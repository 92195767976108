import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import scroll from "../images/scroll.png"
import starter from "../images/starter.png"
import about from "../images/aboutimg.png"
import howwework from "../images/howweworkimg.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="intro container">
      <div className="intro_text">
    <h1 className="hIntro">Cynesige Web Development</h1>
    <p className="pIntro">We build websites for companies and individuals that stand out.</p>
        
        <Link to="/contact"> <button>Contact</button></Link>
        
      </div>
      <div className="intro_text" style={{
        display: "inline-block"
      }}> <img src={starter} alt="code window"></img></div>
      </div>
        <div style={{textAlign: "center"}}> <a href="#about"> <img className="scrollbtn" style={{
          margin: "0 auto",
          filter: "invert(1)",
          left: "0",
          right: "0"
          }} src={scroll} alt="scroll down"></img></a></div>
    
     <div className="about container" id="about">
    <div className="intro_text about_img" style={{
        display: "inline-block"
      }}> <img className="about_howwework_img" src={about} alt="about illustration"></img></div>
   <div className="intro_text about_text">
      <h2 style={{fontSize:'1.75rem'}} >About us</h2>
      <p>Cynesige Web Development is a web development agency with origin in Sweden. We are a team of two who build websites for companies and individuals. We know the importance to be visible on the web. Whether you are a company with a thousand employees globally or just a company of your own, 
        having a place on the web where your clients can find out more about you is essential.</p>
      </div>
    </div>
    <div className="colorbreaker">
    <div className="services container">
      <div className="howwework">
          <div className="howwework_text text_left">
            <h2>How we work</h2>
            <p>We offer a wide variety of services within web development for both companies and individuals to ensure that you will be able to focus on what you do best. It is important for us to have a dialog with the customer throughout the whole project.</p>
          </div>
          <div className="howwework_text" style={{
        display: "inline-block"
      }}> <img src={howwework} alt="how we work"></img></div>
        </div>

          <div className="threeColHolder">
          <div className="threeCol">
              <h2>Strategy / SEO</h2>
              <p>We know the importance of being visible on search engines, hence we always make sure your webpage is completely optimized according to the guidelines search engines provide. Together with the client, we make a plan and a strategy for the project.</p>
            </div>
            <div className="threeCol">
              <h2>Design</h2>
              <p>Nowadays people spend way more time on their phone than on the computer. Therefore is the mobile version at least as important as the regular one. Every website we design is responsive and works on all devices with different screens.</p>
            </div>
            <div className="threeCol">
              <h2>Development</h2>
              <p>While you bring us your ideas and preferences, we take care of all coding. This means that no knowledge is required from your side. Our vision is to code as efficient as possible in order to improve the performance of your website.</p>
            </div>
            
          </div>
      </div>
      </div>
      <div className="contact container">
      <h2>Would you like to work with us?</h2>
      <p>Fill out the form below or send us a message on one of our other platforms. We would love to hear your ideas.</p>
      
      <div className="contactform">
      <form name="contact" method="post" data-netlify="true" action="/">
        <input type="hidden" name="form-name" value="contact" />
        <input name="name" placeholder="Name" type="text"/>
        <input name="email" placeholder="Email adress" type="email"/>
        <textarea name="message" placeholder="Message..."></textarea>
        <button style={{fontSize: '18px', height: '37px', width: '85px'}} type="submit">Send</button>
      
    </form>
    </div>
      </div>
  </Layout>
)

export default IndexPage
